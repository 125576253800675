import { EditorSDK } from '@wix/platform-editor-sdk';
import { WidgetEventHandlers, WidgetEditor } from '../../types/editor';
import {
  openSettingsPanel,
  addComponent,
  findComponentsByType,
} from '../../utils/editor';
import { id as customElementId } from './.component.json';
import {
  getCustomElementStructure,
  IWebComponent,
} from './elements/customElement';

const TOKEN = 'token';

export async function install(editorSDK: EditorSDK, appDefinitionId: string) {
  const applicationId = await editorSDK.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );
  // @ts-ignore
  const webComponent: IWebComponent = appData.components.find(
    (comp: { type: string }) => comp.type === 'WEB',
  );
  const customElementComponentRef = await addComponent(
    editorSDK,
    getCustomElementStructure({
      applicationId,
      data: webComponent?.data ?? {},
      instanceId: appData.instanceId,
    }),
  );

  const currentElementRef = await findComponentsByType(
    editorSDK,
    customElementComponentRef,
    'wixui.CustomElementComponent',
  );

  await editorSDK.document.controllers.saveConfiguration(TOKEN, {
    controllerRef: customElementComponentRef,
    config: { componentId: webComponent.componentId },
  });

  await editorSDK.document.controllers.connect(TOKEN, {
    controllerRef: customElementComponentRef,
    connectToRef: currentElementRef[0],
    role: 'webComponent',
    connectionConfig: { time: new Date().getTime() },
  });
}
const customElementEventsHandler: WidgetEventHandlers = {
  widgetGfppClicked: {
    connect: (payload, editorSDK) =>
      openSettingsPanel(editorSDK, customElementId, payload, '123'),
  },
  // componentGfppClicked: {
  //   [SETTINGS_EV_ID]: (payload, editorSDK) =>
  //     openSettingsPanel(editorSDK, customElementId, payload),
  // },
};

function createCustomElement(): WidgetEditor {
  return {
    type: customElementId,
    getEventHandlers() {
      return customElementEventsHandler;
    },
  };
}

export default createCustomElement();
