
  var editorScriptEntry = require('/home/builduser/agent00/work/859cd99042ef5abe/web-component-wrapper/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      var editorScriptBuilder = require('@wix/bob-widget-services').editorScriptBuilder;

      var builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(editorScriptEntry.editorReady);
      }
      if (editorScriptEntry.getAppManifest) {
        builder = builder.withAppManifest(editorScriptEntry.getAppManifest);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      
      var userController_0 = require('/home/builduser/agent00/work/859cd99042ef5abe/web-component-wrapper/src/components/webComponent/editor.controller.ts');
      builder = builder.withWidget(userController_0.default || userController_0);

      module.exports = builder.build();
  }
  
