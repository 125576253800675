export interface IWebComponentData {
  tagName: string;
  settingsUrl: string;
  connectLabel: string;
  modalTitle: string;
  scriptTag: string;
  size: {
    width: number;
    height: number;
  };
}
export interface IWebComponent {
  componentId?: string;
  data?: IWebComponentData;
}

export interface IDashboardComponentData {
  checkStatusUrl?: string;
  embedded?: boolean;
  hideWixSideMenu?: boolean;
  published?: boolean;
  settingsPageUrl?: string;
  url?: string;
}

export interface IDashboardComponent {
  data?: IDashboardComponentData;
}

export const getCustomElementStructure = ({
  applicationId,
  data,
  instanceId,
}: {
  applicationId: string;
  data: {
    size?: { width: number; height: number };
    scriptTag?: string;
    tagName?: string;
  };
  instanceId: string;
}) => {
  const initialAttributes = JSON.stringify({
    wixconfig: JSON.stringify({ instanceId, viewMode: 'Editor', data: {} }),
  });
  return {
    componentType: 'platform.components.AppWidget',
    type: 'Container',
    data: {
      applicationId,
      id: 'custom_element_id',
      controllerType: 'webComponent',
      type: 'AppController',
      name: '',
    },
    style: 'appWidget1',
    layout: {
      x: data?.size?.width ? 490 - data?.size?.width / 2 : 400,
      y: 64,
      width: data?.size?.width,
      height: data?.size?.height,
    },
    components: [
      {
        componentType: 'wixui.CustomElementComponent',
        data: {
          url: `${
            data?.scriptTag?.indexOf('?') !== -1
              ? `${data.scriptTag}&instanceId=${instanceId}`
              : `${data.scriptTag}?instanceId=${instanceId}`
          }`,
          tagName: data.tagName,
          type: 'CustomElement',
          role: 'webComponent',
          initialAttributes,
        },
        layout: {
          x: 0,
          y: 0,
          width: '100%',
          height: '100%',
        },
        style: {},
        config: {},
        // connections: {
        //   type: 'ConnectionList',
        //   items: [
        //     {
        //       type: 'ConnectionItem',
        //       role: 'webComponent_Role',
        //       controllerId: 'custom_element_id',
        //       config: '{}',
        //       isPrimary: true,
        //     },
        //   ],
        // },
      },
    ],
  };
};
