import { EditorSDK, ComponentRef } from '@wix/platform-editor-sdk';
import { IWebComponent } from '../components/webComponent/elements/customElement';
import axios from 'axios';

const TOKEN = 'token';
const GENERAL_APPLICATION_ID = '22bef345-3c5b-4c18-b782-74d4085112ff';

const getEditorSdkSource = (editorSDK: EditorSDK) =>
  editorSDK.info.getSdkVersion(TOKEN).scriptSrc;

export const panelUrlBuilder = (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
  panelName: string,
  dynamicSettings: boolean = false,
) => {
  const inWatchMode = process.env.NODE_ENV !== 'production';
  // During yoshi-flow-editor start we want have local rendered settings panel. For prod - we are using static html file.
  const baseUrl = inWatchMode
    ? `https://localhost:3000/settings/${panelName}`
    : (dynamicSettings ? `https://www.wix.com/_serverless/dynamic-settings-server` : `./settings/${panelName}.html`);

  return `${baseUrl}?wix-sdk-version=${getEditorSdkSource(
    editorSDK,
  )}&componentId=${componentRef.id}`;
};

export async function openSettingsPanel(
  editorSDK: EditorSDK,
  panelName: string,
  { componentRef }: { componentRef: ComponentRef },
  appDefinitionId: string,
  title: string = 'Settings',
  height: number = 582,
  width: number = 402,
) {
  const appId = await editorSDK.document.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
  } = await editorSDK.document.tpa.app.getDataByAppDefId(appId, appId);

  const controllerData = await editorSDK.document.controllers.getData(TOKEN, {
    controllerRef: componentRef,
  });

  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string; componentId: string }) =>
          comp.type === 'WEB' &&
          comp.componentId === controllerData?.config?.componentId,
      )) ||
    {};

  // get application data to set the title, height, width
  editorSDK.editor.openComponentPanel(TOKEN, {
    title: webComponent?.data?.modalTitle ?? title,
    url: panelUrlBuilder(editorSDK, componentRef, panelName, appData?.appDefinitionId !== 'aeecfaf9-9455-4457-b99d-265829eaf39f'),
    height,
    width,
    componentRef,
  });
}

export async function addComponent(
  editorSDK: EditorSDK,
  componentDefinition: any,
) {
  const pageRef = await editorSDK.pages.getCurrent(TOKEN);

  return editorSDK.document.components.add(TOKEN, {
    componentDefinition,
    pageRef,
  });
}

export async function findComponentsByType(
  editorSDK: EditorSDK,
  parentRef: ComponentRef,
  componentType: string,
) {
  const children = await editorSDK.document.components.getChildren(TOKEN, {
    componentRef: parentRef,
    recursive: true,
  });

  const result: Array<ComponentRef> = [];

  await Promise.all(
    children.map(async (child) => {
      const type = await editorSDK.components.getType(TOKEN, {
        componentRef: child,
      });

      if (type === componentType) {
        result.push(child);
      }
    }),
  );

  return result;
}

export async function isEligibleForUpgrade(
  editorSDK: EditorSDK | null,
  applicationId: string
): Promise<boolean> {
  if(!editorSDK) {
    return false;
  }
  try {
    const generalApp = await editorSDK.document.tpa.app.getDataByAppDefId(
      GENERAL_APPLICATION_ID,
      GENERAL_APPLICATION_ID,
    );
    const generalInstance = generalApp.instance;

    const managedAppsData = await axios.get(
      'https://editor.wix.com/_api/marketplace-api/v1/managed-apps',
      { headers: { Authorization: generalInstance }, withCredentials: true }
    ); // TODO: support editor x and ADI

    const managedWebSolutions = managedAppsData?.data?.managedWebSolutions ?? [];

    return managedWebSolutions.some(
      (item: { eligibleForUpgrade: boolean; webSolutionBase: { id: string } }) =>
        item?.webSolutionBase?.id === applicationId && item.eligibleForUpgrade
    );
  } catch (error) {
    // console.log('Error fetch managed apps');
    return false;
  }
}
