import {
  EditorSDK,
  EditorReadyOptions,
  GfppButtonConfig,
} from '@wix/platform-editor-sdk';
import { install } from './components/webComponent/editor.controller';
import { IWebComponent } from './components/webComponent/elements/customElement';
import { isEligibleForUpgrade } from './utils/editor';

let gfppSetup: any = () => ({
  desktop: {
    mainAction1: {
      actionId: 'connect',
      label: 'Settings',
    },
    mainAction2: 'HIDE',
    iconButtons: {
      animation: GfppButtonConfig.HIDE,
    },
    helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
  },
});
export async function editorReady(
  editorSDK: EditorSDK,
  appDefinitionId: string,
  { firstInstall }: EditorReadyOptions,
) {
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );

  const isUpgrade: boolean = await isEligibleForUpgrade(editorSDK, appDefinitionId);
  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string }) => comp.type === 'WEB',
      )) ||
    {};

  gfppSetup = () => {
    const { connectLabel } = webComponent.data || {};
    return {
      desktop: {
        mainAction1: {
          actionId: 'connect',
          label: connectLabel || 'Settings',
        },
        mainAction2: 'HIDE',
        iconButtons: {
          animation: GfppButtonConfig.HIDE,
          upgrade: isUpgrade ? GfppButtonConfig.DEFAULT : GfppButtonConfig.HIDE,
        },
        helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
      },
    };
  };
  await editorSDK.document.application.reloadManifest();
  if (firstInstall) {
    await install(editorSDK, appDefinitionId);
  }
}

export const getAppManifest = () => {
  return {
    controllersStageData: {
      webComponent: {
        default: {
          gfpp: gfppSetup(),
        },
      },
    },
    exports: {
      webComponent: {
        tagname: 'webComponent',
        widgetDisplayName: '',
        eventHandlers: {},
        synthetic: false,
        inherits: {},
      },
    },
  };
};
